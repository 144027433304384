import TagManager from 'react-gtm-module';

let is_running = false;
function GoogleTag(id) {
    if (is_running == true) {
        return;
    }
    is_running = true;
    const tagManagerArgs = {
        gtmId: id,
    };
    TagManager.initialize(tagManagerArgs);
    is_running = false;
    return;
}

export default GoogleTag;
