import ReactPixel from 'react-facebook-pixel';

let is_running = false;
function FacebookPixel(id) {
    if (is_running == true) {
        return;
    }
    is_running = true;
    const options = {
        autoConfig: true,
        debug: false,
    };
    ReactPixel.init(id, options);
    ReactPixel.pageView();
    is_running = false;
    return;
}

export default FacebookPixel;
