import React, { Suspense, useEffect, useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Cartcontext } from '../src/context/Context';
import myAxios from './Components/AxiosHeader';
import { URL } from './Components/AxiosUrlGlobal';
import axios from 'axios';
import ReactLoading from 'react-loading';
// Import your helper functions
import FaceBookPixel from './Components/Helper/FacebookPixel';
import GoogleTag from './Components/Helper/GoogleTag';
import GoogleAnalytic from './Components/Helper/GoogleAnalytic';

// Lazy load your components
const Header = React.lazy(() => import('./Components/Header/Header'));
const Invest = React.lazy(() => import('./Components/Invest/Invest'));
const Products = React.lazy(() => import('./Components/Products/Products'));
const Landing = React.lazy(() =>
    import('./Components/Landing/components/Home')
);
const Landing1 = React.lazy(() =>
    import('./Components/Landing1/components/Home')
);
const ProductsNew = React.lazy(() =>
    import('./Components/ProductsNew/ProductsNew')
);
const CheckOut = React.lazy(() => import('./Components/CheckOut/CheckOut'));
const Thanks = React.lazy(() => import('./Components/Thanks/Thanks'));
const NotFound = React.lazy(() => import('./Components/NotFound/NotFound'));
const ViewCategory = React.lazy(() =>
    import('./Components/ViewCategory/ViewCategory')
);
const QuickLinkPage = React.lazy(() =>
    import('./Components/QuickLink/QuickLink')
);
const Footer = React.lazy(() => import('./Components/Footer/Footer'));
const Home = React.lazy(() => import('./Components/Home/Home'));
const Suspend = React.lazy(() => import('./Components/Suspend/Suspend'));
const DealView = React.lazy(() => import('./Components/DealView/DealView'));
const BkashHome = React.lazy(() => import('./Components/Bkash/Home'));
const Success = React.lazy(() => import('./Components/Bkash/Success'));
const Fail = React.lazy(() => import('./Components/Bkash/Fail'));
const Callback = React.lazy(() => import('./Components/Bkash/Callback'));
const Refund = React.lazy(() => import('./Components/Bkash/Refund'));

function App() {
    const Globalstate = useContext(Cartcontext);
    const { setColor, setstoreData, ipAddress } = Globalstate;

    useEffect(() => {
        const setVisitors = async () => {
            try {
                await axios.post(`${URL}/set-visitors`, {
                    ip: ipAddress,
                });
            } catch (error) {
                console.error('Error setting the visitor IP address:', error);
            }
        };

        if (ipAddress) {
            setVisitors();
        }
    }, [ipAddress]);

    useEffect(() => {
        const storedColor = localStorage.getItem('storeColor');
        if (storedColor) {
            setColor(storedColor);
        }

        myAxios
            .get(`${URL}/e-store`)
            .then((res) => {
                if (res.data.color) {
                    setColor(res.data.color);
                    localStorage.setItem(
                        'storeColor',
                        JSON.stringify(res.data.color)
                    );
                }
                setstoreData(res.data);
                if (res.data.suspended) {
                    window.location.href = '/suspend';
                }
                if (res.data.pixel) {
                    FaceBookPixel(res.data.pixel);
                }

                if (res.data.GoogleAdsTag) {
                    GoogleTag(res.data.GoogleAdsTag);
                }

                if (res.data.GoogleAnalytic) {
                    GoogleAnalytic(res.data.GoogleAnalytic);
                }
            })
            .catch((err) => console.log(err.response.data));
    }, [setColor, setstoreData]);

    return (
        <Suspense
            fallback={
                <div className="Suspense">
                    <ReactLoading
                        type={'spin'}
                        color={'#333'}
                        height={50}
                        width={50}
                    />
                </div>
            }
        >
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/index.html" element={<Home />} />
                    <Route path="/products/:id/:fid?" element={<Products />} />
                    <Route
                        path="/product-new/:id/:fid?"
                        element={<ProductsNew />}
                    />
                    <Route
                        path="/view-category/:id/:pid?"
                        element={<ViewCategory />}
                    />
                    <Route path="/deal-view/:id" element={<DealView />} />
                    <Route path="/checkout" element={<CheckOut />} />
                    <Route path="/landing/:id" element={<Landing />} />
                    <Route path="/landing1/:id" element={<Landing1 />} />
                    <Route path="/link/:pageType" element={<QuickLinkPage />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="Invest" element={<Invest />} />
                    <Route path="/bhome" element={<BkashHome />} />
                    <Route path="/callback" element={<Callback />} />
                    <Route path="/thanks" element={<Thanks />} />
                    <Route path="/success" element={<Success />} />
                    <Route path="/fail" element={<Fail />} />
                    <Route path="/refund" element={<Refund />} />
                    <Route path="/suspend" element={<Suspend />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
